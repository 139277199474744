import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61a523d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-master-data-unit-of-measures-list-form-remove" }
const _hoisted_2 = { class: "app-administrator-master-data-unit-of-measures-list-form-remove__action" }
const _hoisted_3 = { class: "el-dialog__message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isDialogVisible = true), ["prevent"]))
      }, [
        _createVNode(_component_Delete)
      ])
    ]),
    _createVNode(_component_el_dialog, {
      title: _ctx.translation.remove,
      class: "el-dialog--confirmation",
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString(_ctx.translation.areYouSure), 1)
        ]),
        _createVNode(_component_el_form, {
          class: "el-form--default",
          model: _ctx.formData,
          ref: "formComponent",
          onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  class: "el-button--primary el-button--block",
                  "native-type": "submit",
                  loading: _ctx.isLoading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translation.yesIAm), 1)
                  ]),
                  _: 1
                }, 8, ["loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "onSubmit"])
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}