export interface AppUnitOfMeasureIndex {
  id: string;
  organization_id: string | string[];
  content: {
    title: string;
  }
  created_at: string;
  updated_at: string;
}

export class AppUnitOfMeasureIndex implements AppUnitOfMeasureIndex {
  id: string;
  organization_id: string | string[];
  content: {
    title: string;
  }
  created_at: string;
  updated_at: string;

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.content = {
      title: "",
    };
    this.created_at = "";
    this.updated_at = "";
  }
}